import { prepareTcfString, resetConsentChangeHash } from "../..";
import { getDefaultDecision } from "@devowl-wp/cookie-consent-web-client";
const cancelEvent = e => {
  if (!e) {
    return;
  }
  e.preventDefault();

  // Some themes (e.g. AKEA) do have listeners on each link separately and do a custom redirect
  e.stopPropagation();
};
function useBannerStateContextCallbacks() {
  let {
    toggleOverlay
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const toggleWithActiveAction = (contextValue, type) => Object.assign(contextValue, {
    activeAction: type,
    individualPrivacyOpen: true,
    refreshSiteAfterSave: type === "change" ? 2000 : false,
    ...toggleOverlay(contextValue, true)
  });
  return {
    onClose: state => {
      Object.assign(state, {
        ...toggleOverlay(state, false),
        // This should be set always by you again as it is one-time
        refreshSiteAfterSave: false
      });
    },
    openHistory: (contextValue, e) => {
      toggleWithActiveAction(contextValue, "history");
      cancelEvent(e);
    },
    openBanner: (contextValue, e) => {
      toggleWithActiveAction(contextValue, "change");
      cancelEvent(e);
    },
    revokeConsent: (_ref, successMessage, e) => {
      let {
        onApplyConsent,
        isTcf,
        tcf,
        isGcm,
        groups,
        essentialGroup
      } = _ref;
      // Do not rely on current state of `groups` because it can be altered through history change
      const buttonClicked = "shortcode_revoke";
      let tcfString;
      if (process.env.IS_TCF === "1" && isTcf && tcf !== null && tcf !== void 0 && tcf.model) {
        tcfString = prepareTcfString(tcf.model, buttonClicked);
      }
      const defaultConsent = getDefaultDecision({
        essentialGroupSlug: essentialGroup,
        groups
      }, true);
      onApplyConsent({
        consent: defaultConsent,
        gcmConsent: isGcm ? [] : undefined,
        buttonClicked,
        tcfString
      }).then(() => {
        successMessage && alert(successMessage);
        resetConsentChangeHash();

        // Always reload (always wait 2s to ensure opt-out scripts are exeucted)
        // Fix iOS / Safari bug when we want to reload the page after a POST request
        setTimeout(() => window.location.reload(), 2000);
      });
      cancelEvent(e);
    },
    onSave: (contextValue, markAsDoNotTrack, buttonClicked) => {
      const {
        onApplyConsent,
        activeAction,
        consent,
        tcf,
        isTcf,
        isGcm,
        refreshSiteAfterSave,
        gcmConsent,
        recorder
      } = contextValue;

      // TCF compatibility: persist TCF string
      let tcfString;
      if (process.env.IS_TCF === "1" && isTcf && tcf !== null && tcf !== void 0 && tcf.model) {
        tcfString = prepareTcfString(tcf.model, buttonClicked);
      }

      // Google Consent Mode compatibility, only allow
      let useGcmConsent;
      if (process.env.PLUGIN_CTX === "pro" && isGcm) {
        switch (buttonClicked) {
          case "ind_all":
          case "ind_custom":
          case "main_all":
          case "main_custom":
            useGcmConsent = gcmConsent;
            break;
          default:
            useGcmConsent = [];
            break;
        }
      }
      const appliedConsent = onApplyConsent({
        consent,
        gcmConsent: useGcmConsent,
        markAsDoNotTrack,
        buttonClicked,
        tcfString,
        recorderJsonString: recorder ? JSON.stringify(recorder.createReplay()) : undefined,
        uiView: activeAction === "change" ? "change" : activeAction !== "revoke" ? "initial" : undefined
      });
      if (refreshSiteAfterSave) {
        appliedConsent.then(() => {
          resetConsentChangeHash();

          // Fix iOS / Safari bug when we want to reload the page after a POST request
          setTimeout(() => window.location.reload(), refreshSiteAfterSave || /* BC */2000);
        });
      } else {
        Object.assign(contextValue, toggleOverlay(contextValue, false));
      }
    },
    updateCookieChecked: (contextValue, groupId, id, checked) => {
      const {
        consent,
        isGcm,
        groups,
        updateGcmConsentTypeChecked
      } = contextValue;
      if (!consent[groupId]) {
        consent[groupId] = [];
      }
      const cookieIds = consent[groupId];
      const indexOf = cookieIds.indexOf(id);
      if (checked && indexOf === -1) {
        cookieIds.push(id);
      } else if (!checked && indexOf > -1) {
        cookieIds.splice(indexOf, 1);
      }

      // Delete group entirely when no cookies selected
      if (!cookieIds.length) {
        delete consent[groupId];
      }

      /* onlypro:start */
      if (process.env.PLUGIN_CTX === "pro" && isGcm) {
        const acceptedServices = groups.map(_ref2 => {
          let {
            id: groupId,
            items
          } = _ref2;
          return items.filter(_ref3 => {
            var _consent$groupId;
            let {
              id: serviceId
            } = _ref3;
            return ((_consent$groupId = consent[groupId]) === null || _consent$groupId === void 0 ? void 0 : _consent$groupId.indexOf(serviceId)) > -1;
          });
        }).flat();
        for (const gcmConsentType of groups.find(_ref4 => {
          let {
            id
          } = _ref4;
          return id === groupId;
        }).items.find(_ref5 => {
          let {
            id: serviceId
          } = _ref5;
          return id === serviceId;
        }).googleConsentModeConsentTypes) {
          if (checked) {
            // Automatically check all GCM consent purposes whne a service got accepted
            updateGcmConsentTypeChecked(gcmConsentType, true);
          } else if (
          // We only uncheck this consent purpose when it is not requested by another accepted service
          !acceptedServices.some(_ref6 => {
            let {
              googleConsentModeConsentTypes
            } = _ref6;
            return googleConsentModeConsentTypes.includes(gcmConsentType);
          })) {
            updateGcmConsentTypeChecked(gcmConsentType, false);
          }
        }
      }
      /* onlypro:end */
    },

    updateGroupChecked: (contextValue, groupId, checked) => {
      const {
        groups,
        updateCookieChecked
      } = contextValue;
      for (const item of groups.find(_ref7 => {
        let {
          id
        } = _ref7;
        return id === groupId;
      }).items) {
        updateCookieChecked(groupId, item.id, checked);
      }
    }
  };
}
export { useBannerStateContextCallbacks };