import { Fragment } from "react";
import { useBanner, BANNER_GROUP_NON_STANDARD_STYLE, useGroupItems } from "../../..";
import { Group, GroupList, GcmConsentType } from "../../common";
import { findUsedGcmConsentTypes } from "@devowl-wp/cookie-consent-web-client";
const BannerGcmGroupList = () => {
  const banner = useBanner();
  const {
    i18n: {
      purposes,
      gcm: {
        standard,
        standardDesc
      }
    }
  } = banner;
  const allServices = useGroupItems();
  const services = findUsedGcmConsentTypes(allServices);
  return h(Fragment, null, h(Group, {
    headline: standard,
    style: BANNER_GROUP_NON_STANDARD_STYLE,
    borderless: true
  }, standardDesc), h(GroupList, null, h(Group, {
    headline: purposes
  }, services.map(type => h(GcmConsentType, {
    key: type,
    type: type,
    isBold: true
  })))));
};
export { BannerGcmGroupList };