import { EServiceTemplateGoogleConsentModeTypes } from "@devowl-wp/api-real-cookie-banner";
import { APPLY_INTERACTIVE_EVENT } from "../events";
import { getUserGcmDecision } from ".";
import { PRESET_ID_TAG_MANAGERS } from "../apply";
import { Cookie } from "..";

/**
 * Dispatch events to the `gtag` so the Google Consent Mode can work with the consent status.
 */
function gcmDispatcher(_ref) {
  let {
    gcmCookieName,
    groups,
    tagManager
  } = _ref;
  document.addEventListener(APPLY_INTERACTIVE_EVENT, _ref2 => {
    let {
      detail: {
        services: acceptedServices
      }
    } = _ref2;
    const {
      gtag
    } = window;
    const cookieExists = !!Cookie.get(gcmCookieName);
    if (gtag && cookieExists) {
      const consent = getUserGcmDecision(gcmCookieName);
      gtag("consent", "update", {
        // Custom consent types representing our unique name of each service
        ...(tagManager === "googleTagManagerWithGcm" ? groups.map(_ref3 => {
          let {
            items
          } = _ref3;
          return items;
        }).flat().reduce((p, _ref4) => {
          let {
            id,
            uniqueName
          } = _ref4;
          // @ts-expect-error Unique name can be every preset ID
          if (uniqueName && PRESET_ID_TAG_MANAGERS.indexOf(uniqueName) === -1) {
            p[uniqueName] = acceptedServices.some(_ref5 => {
              let {
                service: {
                  id: acceptedId
                }
              } = _ref5;
              return acceptedId === id;
            }) ? "granted" : "denied";
          }
          return p;
        }, {}) : []),
        // Predefined consent types
        ...Object.values(EServiceTemplateGoogleConsentModeTypes).reduce((p, c) => {
          p[c] = consent.indexOf(c) > -1 ? "granted" : "denied";
          return p;
        }, {})
      });
    }
  });
}
export { gcmDispatcher };