function useStateContextCallbacks() {
  return {
    updateGcmConsentTypeChecked: (_ref, type, checked) => {
      let {
        gcmConsent
      } = _ref;
      /* onlypro:start */
      if (process.env.PLUGIN_CTX === "pro") {
        const indexOf = gcmConsent.indexOf(type);
        if (checked && indexOf === -1) {
          gcmConsent.push(type);
        } else if (!checked && indexOf > -1) {
          gcmConsent.splice(indexOf, 1);
        }
      }
      /* onlypro:end */
    }
  };
}

export { useStateContextCallbacks };